import dojo_on = require("dojo/on");

/**
 * Callback for when the page gets hidden (put into a page cache or backgrounded, e.g. in iOS).
 * The callback is wrapped so that is is NOT called on the initial page load (using the
 * pageshow.persisted property, which is false only for the initial load).
 * If the browser does not support this event, this will return null instead of a connection.
 * Note the asymmetry between this case and addOnPageClose - we don't ever want to fall
 * back to binding to onload.
 */
export function addOnPageReshow(func: () => void): dojo_on.Handle | null {
    if (window.onpageshow || window.onpageshow === null) {
        const wrapped = function (e: PageTransitionEvent) {
            if (e.persisted) {
                func();
            }
        };
        return dojo_on(window, "pageshow", wrapped as EventListener);
    }
    return null;
}

/**
 * Callback for when the page gets hidden (put into a page cache or backgrounded, e.g. in iOS) or closed.
 * If the browser supports onpagehide, that event is used preferentially.
 * If the browser does not support this event, this will connect to onunload instead.
 * If you want to popup a confirmation dialog before unloading, do so by binding to onbeforeunload.
 */
export function addOnPageClose(func: (evt: Event) => void): dojo_on.Handle {
    if (window.onpagehide || window.onpagehide === null) {
        return dojo_on(window, "pagehide", func);
    }
    return dojo_on(window, "unload", func);
}

/** Wraps a function so that it will not be called until the user can see the page. */
export function whenPageVisible(callback: () => void): () => void {
    return function f() {
        if (document.hidden) {
            dojo_on.once(document, "visibilitychange", f);
        } else {
            callback();
        }
    };
}
