// LocalStorage - wrapper around localStorage to catch browser-specific issues..

let ls: Storage | null;
const key = "foobarbaz";
try {
    ls = localStorage;
    ls.setItem(key, "1");
    if (ls.getItem(key) !== "1") {
        ls = null;
    } else {
        ls.removeItem(key);
    }
} catch (err) {
    // The only error we really expect to catch here is a QuotaExceededException thrown by Safari
    // when in incognito mode on OSX/iOS7.
    ls = null;
}

export function getItem(k: string): string | null {
    return ls === null ? null : ls.getItem(k);
}

export function setItem(k: string, v: string): void {
    if (ls !== null) {
        ls.setItem(k, v);
    }
}

export function removeItem(k: string): void {
    if (ls !== null) {
        ls.removeItem(k);
    }
}
