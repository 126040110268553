import Dom = require("Everlaw/Dom");
import Input = require("Everlaw/Input");
import Is = require("Everlaw/Core/Is");
import LocalStorage = require("Everlaw/LocalStorage");
import Util = require("Everlaw/Util");
import Widget = require("Everlaw/UI/Widget");
import baseWindow = require("dojo/_base/window");
import dojo_on = require("dojo/on");
import dojo_topic = require("dojo/topic");
import dojo_window = require("dojo/window");
import eventUtil = require("dojo/_base/event");
import { IconButton } from "Everlaw/UI/Button";
import { addOnPageClose } from "Everlaw/DojoUtil";

interface Position {
    w?: number;
    h?: number;
    x?: number;
    y?: number;
    visible?: boolean;
}

interface Proportion {
    w?: number;
    h?: number;
    favorRight?: boolean;
    favorBottom?: boolean;
}

const DEFAULT_DIM = 200;

class FloatingPanel extends Widget {
    private title: string;
    protected class: string;
    protected content: Dom.Content;
    protected contentNode: HTMLElement;
    protected container: HTMLElement;
    protected resizer: HTMLElement | null;
    private proportionalPosition: boolean;
    private proportion: Proportion = {};
    private minHeight: number;
    private minWidth: number;
    private cookieName: string;
    private onResize: (w: number, h: number) => void;
    private onMove: (x: number, y: number) => void;
    private onToggle: (open: boolean, fromX: boolean) => void;
    private dragConnect: dojo_on.Handle;
    private dragOrigin: { x: number; y: number };
    private state = false;
    protected position: Position = {};
    titleNode: HTMLElement;
    titleH2InnerNode: HTMLElement;
    private titleRowNode: HTMLElement;
    protected resizable: boolean;
    // If true, this FloatingPanel will close if a 'close-floating-panels' event occurs. Use
    // UI.closeFloatingPanels() to publish this event. See that function for more information.
    private listenForCloseEvents: boolean;
    private closeListener: { remove(): void } = null;
    private onBlur: () => void;
    private focusable: boolean = true;

    constructor(params: FloatingPanel.Params) {
        super();
        this.title = params.title;
        this.class = params.class || "";
        this.content = params.content;
        this.container = params.container;
        this.proportionalPosition = params.proportionalPosition;
        this.minWidth = params.minWidth || DEFAULT_DIM;
        this.minHeight = params.minHeight || DEFAULT_DIM;
        this.resizable = Is.defined(params.resizable) ? params.resizable : true;
        this.onResize = params.onResize;
        this.onMove = params.onMove;
        this.onToggle = params.onToggle;
        this.onBlur = params.onBlur;
        this.cookieName = params.cookieName;
        this.listenForCloseEvents = params.listenForCloseEvents;
        this.build();
        this.loadPanelCookies();
        this.focusable = !params.notFocusable;
        if (this.focusable) {
            Dom.setAttr(this.node, "tabindex", "-1");
            this.registerDestroyable(
                Input.fireCallbackOnKey(this.node, [Input.ESCAPE], () => this.toggle()),
            );
        }
    }

    protected build() {
        this.titleH2InnerNode = Dom.h2({ class: "dialog-title" }, this.title);
        this.titleNode = Dom.span({ class: "dijitDialogTitle" }, this.titleH2InnerNode);
        const closeNode = new IconButton({
            iconClass: "x-20",
            onClick: () => this.set(false, false, true),
        });
        const titleRow = Dom.div(
            {
                class: "dijitDialogTitleBar",
            },
            this.titleNode,
            closeNode.node,
        );
        this.titleRowNode = titleRow;
        this.resizer = this.resizable
            ? Dom.div({
                  class: "resizer",
              })
            : null;
        const contentContainer = Dom.div(
            {
                class: "floating-panel-content",
            },
            this.content,
        );
        this.resizable && this.connect(this.resizer, Input.press, this.resizeStart.bind(this));
        this.connect(titleRow, Input.press, this.moveStart.bind(this));
        this.connect(window, Input.release, this.dragEnd.bind(this));
        this.connect(window, "resize", this.resizeWindow.bind(this));
        this.registerDestroyable(addOnPageClose(this.savePanelCookies.bind(this)));
        this.contentNode = contentContainer;
        this.node = this.buildNode();
    }

    protected buildNode(): HTMLElement {
        return Dom.create(
            "div",
            {
                class: "floating-panel closed-panel " + this.class,
                content: [this.titleRowNode, this.contentNode, this.resizer],
            },
            this.container || baseWindow.body(),
        );
    }
    private loadPanelCookies() {
        // First, load sensible defaults.
        this.resize(DEFAULT_DIM, DEFAULT_DIM);
        const width = Math.max(DEFAULT_DIM, this.minWidth);
        const height = Math.max(DEFAULT_DIM, this.minHeight);
        this.center(width, height);
        if (this.cookieName) {
            const storedVal = LocalStorage.getItem(this.cookieName);
            if (storedVal) {
                const pos = JSON.parse(storedVal);
                // We check two sets of names here (width/height/top/left, w/h/y/x) for backwards
                // compatibility.
                if (Is.number(pos.width || pos.w) && Is.number(pos.height || pos.h)) {
                    this.resize(pos.width || pos.w, pos.height || pos.h);
                }
                if (Is.number(pos.top || pos.y) && Is.number(pos.left || pos.x)) {
                    this._move(pos.left || pos.x, pos.top || pos.y, true);
                }
                if (pos.visible) {
                    this.set(true);
                }
            }
        }
    }

    /**
     * Center the floating panel content in {@link container} (or `window` if no container).
     * @param width current width of content
     * @param height current height of content
     */
    protected center(width: number, height: number): void {
        let x;
        let y;
        if (this.container) {
            const domRect = this.container.getBoundingClientRect();
            x = Math.max(domRect.left + (this.container.offsetWidth - width) / 2, 0);
            y = Math.max(domRect.top + (this.container.offsetHeight - height) / 2, 0);
        } else {
            x = Math.max((window.innerWidth - width) / 2, 0);
            y = Math.max((window.innerHeight - height) / 2, 0);
        }
        this.move(x, y, true);
    }

    private savePanelCookies() {
        if (this.cookieName) {
            const pos = this.getPosition();
            pos.visible = this.state;
            LocalStorage.setItem(this.cookieName, JSON.stringify(pos));
        }
    }

    resizeWindow() {
        if (this.proportionalPosition && this.container) {
            const domRect = this.container.getBoundingClientRect();
            const x = this.proportion.favorRight
                ? this.proportion.w * domRect.width
                : this.proportion.w * domRect.width + domRect.left;
            const y = this.proportion.favorBottom
                ? this.proportion.h * domRect.height
                : this.proportion.h * domRect.height + domRect.top;
            this._move(x, y);
        } else {
            this._move(this.position.x, this.position.y);
        }
    }

    private resizeStart(e) {
        this.dragEnd();
        this.dragConnect = dojo_on(window, Input.move, (e) => {
            this.handleMoveEvent(e, true);
        });
        const pos = Dom.position(this.node);
        this.dragOrigin = { x: e.screenX - pos.w, y: e.screenY - pos.h };
        Dom.addClass(this.node, "resizing");
    }
    private moveStart(e) {
        this.dragConnect = dojo_on(window, Input.move, (e) => {
            this.handleMoveEvent(e, false);
        });
        const pos = Dom.position(this.node);
        this.dragOrigin = { x: e.screenX - pos.x, y: e.screenY - pos.y };
    }
    private dragEnd() {
        if (this.dragConnect) {
            this.dragConnect.remove();
        }
        this.dragConnect = null;
        const wasResizing = Dom.hasClass(this.node, "resizing");
        Dom.removeClass(this.node, "resizing");
        if (wasResizing && this.onResize) {
            this.onResize(this.position.w, this.position.h);
        }
        if (this.proportionalPosition) {
            this.calcProportions();
        }
    }

    protected bound(v: number, min: number, max: number) {
        return Util.clamp(v, min, max);
    }

    /**
     * Resize the panel (including the title row) to the given width and height in pixels.
     */
    protected resize(w: number, h: number) {
        const windowBox = dojo_window.getBox();
        w = this.bound(w, this.minWidth || 0, windowBox.w);
        h = this.bound(h, this.minHeight || 0, windowBox.h);
        this.position.w = w;
        this.position.h = h;
        Dom.style(this.node, "width", w + "px");
        Dom.style(this.node, "height", h + "px");
    }

    /**
     * Resize the body of the panel to the given width and height in pixels. This should be called
     * when the content can have dynamic width/height; by default the FloatingPanel starts at
     * minWidth/minHeight.
     */
    resizeBody(w: number, h: number) {
        // Add 2 in each direction to account for this.node having a 1px border.
        this.resize(w + 2, h + this.titleRowNode.offsetHeight + 2);
    }

    // returns if should favor proportionally placing the panel to the left or right side
    // returns true for favoring right, false for favoring left
    private favorLeftOrRight() {
        const width = parseInt(this.getNode().style.width);
        const domRect = this.container.getBoundingClientRect();
        const positionMidPoint = this.position.x + width / 2;
        this.proportion.favorRight =
            domRect.right - positionMidPoint < positionMidPoint - domRect.left;
        return this.proportion.favorRight;
    }

    // returns if should favor proportionally placing the panel to the top or bottom side
    // returns true for favoring bottom, false for top
    private favorBottomOrTop() {
        const height = parseInt(this.getNode().style.height);
        const domRect = this.container.getBoundingClientRect();
        const positionMidPoint = this.position.x + height / 2;
        this.proportion.favorBottom =
            domRect.bottom - positionMidPoint < positionMidPoint - domRect.top;
        return this.proportion.favorBottom;
    }

    private calcProportions() {
        if (this.proportionalPosition && this.container) {
            const domRect = this.container.getBoundingClientRect();
            this.proportion.w = this.favorLeftOrRight()
                ? this.position.x / domRect.width
                : (this.position.x - domRect.left) / domRect.width;
            this.proportion.h = this.favorBottomOrTop()
                ? this.position.y / domRect.height
                : (this.position.y - domRect.top) / domRect.height;
        }
    }

    // moves the floating panel to a specified position and calculates proportion
    move(x: number, y: number, silent = false): void {
        this._move(x, y, silent);
        this.calcProportions();
    }

    private _move(x: number, y: number, silent = false) {
        if (this.container != null) {
            const domRect = this.container.getBoundingClientRect();
            const height = parseInt(this.getNode().style.height);
            const width = parseInt(this.getNode().style.width);
            x = this.bound(x, domRect.left, domRect.width + domRect.left - width);
            y = this.bound(y, domRect.top, domRect.height + domRect.top - height);
        } else {
            const windowBox = dojo_window.getBox();
            // Allow the user to drag the right half of the panel off the screen
            x = this.bound(x, 0, windowBox.w - this.position.w / 2);
            // Allow the user to drag all but the title bar (height 38px) off the bottom of the screen
            y = this.bound(y, 0, windowBox.h - 38);
        }
        this.position.x = x;
        this.position.y = y;
        Dom.style(this.node, "left", x + "px");
        Dom.style(this.node, "top", y + "px");
        if (!silent) {
            this.onMove?.(x, y);
        }
    }

    private handleMoveEvent(e, isResize: boolean) {
        this[isResize ? "resize" : "_move"](
            e.screenX - this.dragOrigin.x,
            e.screenY - this.dragOrigin.y,
        );
        eventUtil.stop(e);
    }

    scrollToBottom() {
        this.contentNode.scrollTop = this.contentNode.scrollHeight;
    }

    toggle(silent?: boolean) {
        this.set(!this.state, silent);
    }

    /**
     * Set the state of this floating panel (open/closed).
     * The change can be marked as silent if you don't want to trigger the onToggle callback.
     * @param {type} state: boolean-like (open/closed)
     * @param {type} silent: boolean-like, should this update skip triggering the onToggle callback?
     * @param {type} fromX - did the change come from clicking on the X button in the panel header?
     *                      (users should never really need to provide this argument)
     */
    set(state: boolean, silent?: boolean, fromX?: boolean) {
        state = !!state;
        if (this.state !== state) {
            this.state = state;
            Dom.toggleClass(this.node, "closed-panel", !state);
            !silent && this.onToggle && this.onToggle(state, fromX);
            this.dragEnd();
        }
        if (this.closeListener) {
            this.closeListener.remove();
            this.closeListener = null;
        }
        if (state) {
            this.focusable && setTimeout(() => this.node.focus(), 100);
            if (this.listenForCloseEvents) {
                this.closeListener = dojo_topic.subscribe("close-floating-panels", () => {
                    this.set(false);
                });
            }
        }
    }

    setTitle(title: string) {
        this.title = title;
        Dom.setContent(this.titleNode, this.title);
    }

    isOpen() {
        return this.state;
    }

    getPosition(): Position {
        // These are named to correspond with Dom.geometry
        return {
            w: this.position.w,
            h: this.position.h,
            x: this.position.x,
            y: this.position.y,
        };
    }

    override destroy(): void {
        super.destroy();
        Dom.destroy(this.node);
    }
}

module FloatingPanel {
    export interface Params {
        title?: string;
        class?: string;
        content: Dom.Content;
        container?: HTMLElement;
        proportionalPosition?: boolean;
        minHeight?: number; // dimensions in pixels
        minWidth?: number; // dimensions in pixels
        resizable?: boolean;
        onResize?: (w: number, h: number) => void;
        onMove?: (x: number, h: number) => void;
        /**
         * A callback to be fired when the open/closed state of the panel changes.  It will be called
         * with two arguments: the current state (open is true, closed is false), and whether the change
         * came from clicking on the X button in the panel header (which should only be the case when
         * the panel is now closed).
         */
        onToggle?: (open: boolean, fromX: boolean) => void;
        onBlur?: () => void;
        // If cookieName is provided, the panel will be "sticky" and store/retrieve past position info
        // from local storage.
        cookieName?: string;
        listenForCloseEvents?: boolean;
        // If true, the panel will not be autofocused when shown.
        notFocusable?: boolean;
    }

    /**
     * This event closes all floating panels that were created with listenForCloseEvents = true.
     *
     * If you open a floating panel and then switch to another "context" (e.g. another sidebar tab, or
     * another step in a wizard) without actually navigating to a new page, you'll probably want to
     * close the floating panel. You can use this method (and the listenForCloseEvents option) to do
     * this.
     *
     * Right now this is only being used by SearchTerms.priorSearch, but it might make sense to use for
     * other floating panels on the site as well. (TODO: Try this.) The problem that might arise is that
     * a "close-worthy" context for one floating panel might not be close-worthy for another floating
     * panel.
     */
    export function closeAll() {
        dojo_topic.publish("close-floating-panels");
    }
}

export = FloatingPanel;
